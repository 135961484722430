import { initializeApp } from "firebase/app"

let _instance;

export default (config) => {
    if (typeof window !== 'undefined') {
        if (_instance)
            return _instance

        _instance = initializeApp(config || {})
        return _instance
    }

    return null
}
