import './src/styles/global.css'
import initializeApp from './firebase';
//import { /*ReCaptchaV3Provider,*/ initializeAppCheck } from "firebase/app-check";
import { getAnalytics } from "firebase/analytics";
import { getRemoteConfig } from "firebase/remote-config"
import remoteConfigDefaults from "./remote_config_defaults.json"
//import initializeAppCheck from "./fireabse-app-check"
//import { app } from 'firebase-admin';

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

//import app from "gatsby-plugin-firebase-v9.0"
//import recaptchaConf from "./recaptcha-conf"
//import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check"

/*console.log(`process.env.NODE_ENV`, process.env.NODE_ENV);
if (process.env.NODE_ENV === 'development') {
  console.log(`development debug token`)
  window.FIREBASE_APPCHECK_DEBUG_TOKEN = true
}*/

const firebaseApp = initializeApp({
  apiKey: process.env.GATSBY_FIREBASE_API_KEY,
  authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.GATSBY_FIREBASE_DATABASE_URL,
  projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
  storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.GATSBY_FIREBASE_APP_ID,
  measurementId: process.env.GATSBY_FIREBASE_MEASUREMENT_ID
})

if (
  window.location.hostname === '127.0.0.1' ||
  window.location.hostname === 'localhost'
) {
  window.FIREBASE_APPCHECK_DEBUG_TOKEN = true
}

console.log("Initializing remote config");

const remoteConfig = getRemoteConfig(firebaseApp);
remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
remoteConfig.defaultConfig = remoteConfigDefaults;

if (process.env.NODE_ENV !== "development") {
  /*try {
    initializeAppCheck(firebaseApp, {
      provider: new ReCaptchaV3Provider(process.env.RECAPTCHA_V3_KEY),
      isTokenAutoRefreshEnabled: true,
    });
  
  } catch (e) {
    console.log(`Error initializing App Check`, e);
  }*/
  
  //initializeAppCheck(app)
  getAnalytics(firebaseApp);
}

/*(async () => {
  const request = await fetch('/__/firebase/init.json');
  console.log(`Request`, request);
  const config = await request.json()
  console.log(`Config`, config);
  //const firebaseApp = initializeApp(config);

  const app = firebase({ appId: "demo-lolvi", ...config });

  /*if (config.projectId === 'lolvi-dev') {
    console.log("Firebase was initialized...", config);
  }*/

  /*if (
    process.env.NODE_ENV === 'development' ||
    config.projectId === 'lolvi-dev'
  ) {
    console.log(`Enabling the debug token for development`)*/
  /*if !(
    location.hostname === '127.0.0.1' ||
    location.hostname === 'localhost'
  ) {
    window.FIREBASE_APPCHECK_DEBUG_TOKEN = true
  }*/
/*
  //Enabling functions
  //getFunctions(firebaseApp);

  //Enabling analytics

  if (!(
    window.location.hostname === '127.0.0.1' ||
    window.location.hostname === 'localhost'
  )) {

    if (
      window.location.hostname === 'dev.lolvi.co'
    ) {
      window.FIREBASE_APPCHECK_DEBUG_TOKEN = true
    }

    try {
      initializeAppCheck(app, {
        provider: new ReCaptchaV3Provider(`6Ldgbu4oAAAAAFTkLUw74GK82Auh7pqf8JTHhF7V`),
        isTokenAutoRefreshEnabled: true,
      });
  
    } catch (e) {
      console.log(`Error initializing App Check`, e);
    }

    getAnalytics(app);
  }
})();*/

/*fetch('/__/firebase/init.json').then(async response => {
  console.log('firebase browser', await response.json());
  //firebase.initializeApp(await response.json());
});*/

//Enabling Analytics...
//getAnalytics(app);

try {
  //Enabling Recaptcha...
  //initializeAppCheck(app, {
  //  provider: new ReCaptchaV3Provider(recaptchaConf.publicKey),
  //  isTokenAutoRefreshEnabled: true
  //});
} catch (e) {
  console.log(e)
}


